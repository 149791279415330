import { Flex, Text, Heading } from '@mo/uikit'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import Image from 'next/image'
import ImgBecomingAInvestor from '../../../../public/images/home/home2.png'
import ColoredWordLastHeading from './ColoredWordLastHeading'
import ConnectWalletButton from 'components/ConnectWalletButton'

const Home2 = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <>
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['flex-end', null, null, 'center']}
        justifyContent="center"
        mt={[account ? '280px' : '50px', null, 0]}
        id="homepage-home2"
        style={{ gap: '24px' }}
      >
        <Flex flex="1" flexDirection="column">
          <ColoredWordLastHeading color="#FFFFFF" text={t('Becoming an Investor')} />
          <Text color="#FFFFFF" fontSize="18px" mb="24px" mt="40px">
            {t('So investors using the Mojito Swap platform just in your grasp get attractive benefits')}
          </Text>
          <Flex>{!account && <ConnectWalletButton mr="8px" />}</Flex>
        </Flex>
        <Flex
          height={['auto', null, null, '100%']}
          width={['192px', null, null, '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
          position="relative"
          alignSelf="center"
          justifyContent="center"
        >
          <Image src={ImgBecomingAInvestor} priority placeholder="blur" alt={t('Mojo swap')} />
        </Flex>
      </Flex>
    </>
  )
}

export default Home2
